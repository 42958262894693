<template>
  <div id="Artists" class="artists-list">
    <v-container>
      <div class="page-search-wrapper">
        <v-text-field
          ref="search"
          v-model="search"
          full-width
          hide-details
          :placeholder="$t('search-artists')"
          single-line
          prepend-inner-icon="search"
          class="page-search"
        ></v-text-field>
      </div>
      <v-row dense autofocus>
        <v-col v-for="(artist, i) in filteredArtists" :key="i" cols="12">
          <v-lazy>
            <v-hover v-slot:default="{ hover }">
              <v-card rounded="xl" @click.native="selectArtist(artist)" :id="i">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div class="artist-name">
                    <v-card-title
                      :class="[
                        $vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1',
                      ]"
                      v-text="artist.name"
                    ></v-card-title>
                  </div>
                  <v-avatar class="ma-3" size="90">
                    <v-img :src="artist.src"></v-img>
                  </v-avatar>
                </div>
              </v-card>
            </v-hover>
          </v-lazy>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getArtists } from "@/services/songs";

export default {
  computed: {
    filteredArtists() {
      const search = this.search.toLowerCase();

      if (!search) return this.artists;

      return this.artists.filter((artist) => {
        const text = artist.name.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
  },
  name: "artists",
  metaInfo() {
    return {
      title: "Karaoke Studio D - Izvodjači",
      meta: [
        {
          name: "description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:card",
          content: "Karaoke",
        },
        {
          name: "twitter:site",
          content: "@matricestudiod",
        },
        {
          name: "twitter:title",
          content: "Karaoke Studio D - Izvodjači",
        },
        {
          name: "twitter:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:creator",
          content: "@matricestudiod",
        },
        {
          name: "twitter:image",
          content: "https://karaokesrbija.com/api/stream/LOGO.jpg",
        },
        {
          property: "og:title",
          content: "Karaoke Studio D - Izvodjači",
        },
        {
          property: "og:url",
          content: `${window.location.origin}/artists`,
        },
        {
          property: "og:image",
          content: "https://karaokesrbija.com/api/stream/LOGO.jpg",
        },
        {
          property: "og:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno",
        },
        { property: "og:site_name", content: "Karaoke" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      artists: [],
      search: "",
    };
  },
  methods: {
    selectArtist: function (artist) {
      const artistName = artist.name.replace(/\s/g, '-')
      this.$router.push("/artist/" + artistName);
    },
  },
  beforeMount() {
    let self = this;
    getArtists(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        data.payload.forEach((item) => {
          let src = "/api/stream/" + item.replace(/ /g, "") + ".jpg";
          let artist = {
            name: item,
            src,
          };
          self.artists.push(artist);
        });
        self.artists.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
