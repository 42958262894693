<template>
  <div id="paypal_constainer">
    <v-dialog v-model="dialog" width="50%" persistent>
      <v-card>
        <v-card-title>{{ dialogContent.title }}</v-card-title>
        <v-card-text>
          {{ dialogContent.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction()">
            {{ dialogContent.button }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  capturePaypalOrder,
  createPaypalOrder,
  captureAutoSubscription,
  getPaypalKey,
} from '@/services/paypal';

export default {
  props: {
    amount: {
      default: '0',
    },
    currency: {
      default: 'EUR',
    },
    item: {},
    isAutoPayment: {
      default: true
    }
  },
  computed: {},
  name: 'paypal',
  data() {
    return {
      paypalKey: '',
      style: {
        size: 'responsive',
        color: 'blue',
        shape: 'pill',
      },
      dialog: false,
      dialogContent: {
        title: '',
        text: '',
        action: 'success',
        button: 'Ok',
      },
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    dialogAction() {
      if (this.dialogContent.action === 'error') {
        this.dialog = false;
      } else {
        this.$router.push('/');
      }
    },
    loadPaypalButtons() {
      let self = this;
      paypal
        .Buttons({
          style: {
            color: 'blue',
            shape: 'pill',
            label: 'checkout',
            size: 'responsive',
          },
          // Call your server to set up the transaction
          createOrder: function () {
            let payload = {
              amount: {
                value: self.amount,
                currency_code: self.currency,
              },
              item: self.item,
            };
            return createPaypalOrder(payload);
          },
          // Call your server to finalize the transaction
          onApprove: async function (data, actions) {
            self.$emit('processing-payment');
            let payload = {
              orderId: data.orderID,
              type: 'subscription',
              item: self.item,
            };
            let response = await capturePaypalOrder(payload);
            if (response.error_code === 0) {
              self.$store.state.isLogged = true;
              self.$store.state.user = response.user;
              self.dialogContent.title = 'Uspešna pretplata';
              self.dialogContent.text =
                'Hvala Vam što se pretplatili i podržavate Karaoke Studio D, ukoliko imate predloge za poboljšanje možete nas kontaktirati putem email-a na studiodsrb@hotmail.com';
              self.dialogContent.action = 'success';
              self.dialogContent.button = 'Ok';
              self.$emit('finished-payment');
              self.openDialog();
            } else {
              self.dialogContent.title = 'Greška prilikom obrade';
              self.dialogContent.text =
                'Došlo je do greške prilikom obrade uplate, proverite da li Vam je preplata naplaćena, ukoliko jesu kontaktirajte nas putem email-a na studiodsrb@hotmail.com zbog provere, u suprotnom pokušajte ponovo';
              self.dialogContent.action = 'error';
              self.dialogContent.button = 'Probaj ponovo';
              self.$emit('finished-payment');
              self.openDialog();
              return actions.restart();
            }
          },
        })
        .render('#paypal_constainer');
    },
    loadPaypalSubscriptionButtons() {
      let self = this;
      paypal
        .Buttons({
          style: {
            color: 'blue',
            shape: 'pill',
            label: 'checkout',
            size: 'responsive',
          },
          createSubscription: async function (data, actions) {
            return actions.subscription.create({
              plan_id: self.item.plan_id,
            });
          },
          // Call your server to finalize the transaction
          onApprove: async function (data, actions) {
            self.$emit('processing-payment');
            let payload = {
              paypalAutoSubscriptionId: data.subscriptionID,
              type: 'auto-subscription',
              item: self.item,
            };
            let response = await captureAutoSubscription(payload);
            if (response.error_code === 0) {
              self.$store.state.isLogged = true;
              self.$store.state.user = response.user;
              self.dialogContent.title = 'Uspešna pretplata';
              self.dialogContent.text =
                'Hvala Vam što se pretplatili i podržavate Karaoke Studio D, ukoliko imate predloge za poboljšanje možete nas kontaktirati putem email-a na studiodsrb@hotmail.com';
              self.dialogContent.action = 'success';
              self.dialogContent.button = 'Ok';
              self.$emit('finished-payment');
              self.openDialog();
            } else {
              self.dialogContent.title = 'Greška prilikom obrade';
              self.dialogContent.text =
                'Došlo je do greške prilikom obrade uplate, proverite da li Vam je preplata naplaćena, ukoliko jesu kontaktirajte nas putem email-a na studiodsrb@hotmail.com zbog provere, u suprotnom pokušajte ponovo';
              self.dialogContent.action = 'error';
              self.dialogContent.button = 'Probaj ponovo';
              self.$emit('finished-payment');
              self.openDialog();
              return actions.restart();
            }
          },
        })
        .render('#paypal_constainer');
    },
  },
  watch: {
    isAutoPayment: function (val) {
      const self = this
      if (self.paypalKey) {
        const script = document.createElement('script');
        const formUrl = `https://www.paypal.com/sdk/js?client-id=${self.paypalKey}&currency=${self.currency}`;
        const isSubscription = self.item.plan_id !== '' && self.isAutoPayment;

        script.src = isSubscription ? `${formUrl}&vault=true&intent=subscription` : formUrl;
        script.addEventListener(
            'load',
            isSubscription ? self.loadPaypalSubscriptionButtons : self.loadPaypalButtons,
        );
        document.body.appendChild(script);
      }
    }
  },
  mounted() {
    let self = this;
    getPaypalKey(function (data) {
      if (data.error) {
        self.$emit('show-snackbar', data.payload.status, 'error');
      } else {
        self.paypalKey = data.payload.paypalClientId;
        const script = document.createElement('script');
        const formUrl = `https://www.paypal.com/sdk/js?client-id=${self.paypalKey}&currency=${self.currency}`;
        const isSubscription = self.item.plan_id !== '' && self.isAutoPayment;

        script.src = isSubscription ? `${formUrl}&vault=true&intent=subscription` : formUrl;
        script.addEventListener(
            'load',
            isSubscription ? self.loadPaypalSubscriptionButtons : self.loadPaypalButtons,
        );
        document.body.appendChild(script);
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
